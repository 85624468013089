import React, { ReactElement, useRef } from 'react';
import Box from '@mui/material/Box';

import { SmallHeader } from 'js/components/small-header';
import { PageBody } from 'js/components/page-body';
import { Size, useSize } from 'js/utils/size';
import { works } from 'js/content/works';
import { WorkItem } from 'js/components/work-item';
import Typography from '@mui/material/Typography';

export const Works = (): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const size = useSize<Size>(
    ref,
    'l',
    (domRect: DOMRect) => {
      const { width } = domRect;

      if (width > 600) {
        return 'l';
      } if (width > 400) {
        return 'm';
      }

      return 's';
    },
  );

  return (
    <>
      <SmallHeader />

      <PageBody>
        <Box>
          <Typography
            color="textPrimary"
            variant="h6"
            sx={{ paddingLeft: .5 }}
          >
            Works
          </Typography>

          <Box
            ref={ref}
            sx={{
              display: 'grid',
              gridColumnGap: '8px',
              gridRowGap: '8px',
              overflow: 'hidden',

              ...({
                s: {
                  gridTemplateColumns: 'repeat(1, 1fr)',
                },
                m: {
                  gridTemplateColumns: 'repeat(3, 1fr)',
                },
                l: {
                  gridTemplateColumns: 'repeat(4, 1fr)',
                },
              }[size]),

              '&, & *': {
                boxSizing: 'border-box',
              },
            }}
          >
            {
              works
                .map((work) => (
                  <WorkItem key={work.key} work={work} size={size} />
                ))
            }
          </Box>
        </Box>
      </PageBody>
    </>
  );
};
