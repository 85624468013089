import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Post as PostType } from 'js/content/posts';

export const Post = (
  {
    post,
  }: {
    post: PostType;
  },
): ReactElement => {
  const { key, author, content, date, title } = post;

  return (
    <Paper
      sx={{
        padding: 1.5,
      }}
    >
      <Typography variant="h5">
        <Link
          component={RouterLink}
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'text.primary',
            cursor: 'pointer',

            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          to={`/works/${key}`}
        >
          {title}
        </Link>
      </Typography>

      <Typography
        variant="subtitle2"
      >
        <Box component="span" sx={{ color: '#F7D69F' }}>
          {author}
        </Box>

        <Box component="span" sx={{ opacity: .5 }}>
          {' '}- {format(date, 'eeee, LLLL d, u')}
        </Box>
      </Typography>

      <Typography
        sx={{
          marginTop: .125,

          '& a': {
            color: 'color.primary',
          },

          '& table': {
            border: '1px solid #FFFFFF',

            '& td': {
              padding: .5,
              border: '1px solid rgba(255, 255, 255, .6)',
            },
          },
        }}
        variant="body2"
      >
        <ReactMarkdown
          linkTarget="_blank"
          remarkPlugins={[ remarkGfm ]}
          components={{
            a: ({ href, children }) => {
              return (
                <Link href={href} target="_blank">{children}</Link>
              );
            },
          }}
        >
          {content}
        </ReactMarkdown>
      </Typography>
    </Paper>
  );
}
