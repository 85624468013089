import React, { ReactElement, ReactNode } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { LetsChat } from './lets-chat';

import { ReactComponent as SmallB } from 'img/small-b.svg';
import SvgIcon from '@mui/material/SvgIcon';

export const SmallHeader = (
  {
    subHeader,
  }: {
    subHeader?: ReactNode;
  }
): ReactElement => {
  return (
    <Box
      sx={smallHeaderSx}
    >
      <Box className="foreground">
        <Box className="content">
          <Link
            className="anchor"
            component={RouterLink}
            to="/"
          >
            <SvgIcon
              component={SmallB}
              inheritViewBox
              viewBox="0 0 24 24"
              // sx={{ marginLeft: 1.5 }}
            />

            <Box>
              brett.dev
            </Box>
          </Link>

          <Box className="empty">
          </Box>

          <Box
            className="right"
          >
            <LetsChat />
          </Box>
        </Box>

        <Box>
          {subHeader}
        </Box>
      </Box>
    </Box>
  );
};

const smallHeaderSx = {
  position: 'sticky',
  top: 0,
  display: 'flex',
  backgroundImage: `linear-gradient(
    to bottom,
    #1D2132 0%,
    #24283b 100%
  )`,
  justifyContent: 'center',

  '& .foreground': {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '1024px',
    background: 'rgba(255, 255, 255, .05)',
    
    '& > .content': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      overflow: 'hidden',

      '& > .anchor': {
        display: 'flex',
        fontWeight: 'bold',
        textDecoration: 'none',
        fontSize: '1.125rem',
        color: '#FFFFFF',
        height: '3rem',
        paddingLeft: '.75rem',
        alignItems: 'center',
        gap: 1,

        '&:hover': {
          color: '#F7D69F',

          '& svg': {
            fill: '#F7D69F',
          },
        },
      },

      '& > .empty': {
        flexGrow: 1,
      },

      '& > .right': {
        paddingRight: .75,
      },
    },
  },
};
