import React, { ReactElement, useRef } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { works } from 'js/content/works';
import { Size, useSize } from 'js/utils/size';

import { WorkItem } from './work-item';

export const Works = (): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const size = useSize<Size>(
    ref,
    'l',
    (domRect: DOMRect) => {
      const { width } = domRect;

      if (width > 600) {
        return 'l';
      } if (width > 400) {
        return 'm';
      }

      return 's';
    },
  );

  return (
    <Paper
      ref={ref}
      sx={{
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 1,
          marginX: 2,
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Recent Works
          </Typography>
        </Box>

        {
          works.length > {
            l: 4,
            m: 3,
            s: 5,
          }[size] ? (
            <Box>
              <strong>
                <Link
                  component={RouterLink}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: 'text.primary',
                    cursor: 'pointer',

                    '&:hover': {
                      color: '#F7D69F',
                    },
                  }}
                  to="/works"
                >
                  More

                  <MoreHorizIcon />
                </Link>
              </strong>
            </Box>
          ) : null
        }
      </Box>

      <Box
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            overflow: 'hidden',
            paddingX: 1,
            paddingBottom: 1,

            ...(
              size === 's' ? {
                flexDirection: 'column',
                gap: .5,
              } : {}
            ),

            '& > *': {
              width: {
                l: '25%',
                m: '33%',
                s: '100%',
              }[size],
            },
          }}
        >
          {
            works
              .slice(0, {
                l: 4,
                m: 3,
                s: 5,
              }[size])
              .map((work) => (
                <WorkItem key={work.key} work={work} size={size} />
              ))
          }
        </Box>
      </Box>
    </Paper>
  );
};

