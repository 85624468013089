import React, { ReactElement, ReactNode } from 'react';
import Box from '@mui/material/Box';

export const SmallSubHeader = (
  {
    children,
  }: {
    children?: ReactNode;
  }
): ReactElement => {
  return (
    <Box
      sx={{
        background: 'rgba(0, 0, 0, .1)',
        paddingX: 1,
        paddingY: .5,
      }}
    >
      {children}
    </Box>
  );
}
