import brettDevThumbnail from 'img/work-thumbnails/brett-dev.png';
import fuzionThumbnail from 'img/work-thumbnails/fuzion.png';
import intuitThumbnail from 'img/work-thumbnails/intuit.png';
import kyotocoolingThumbnail from 'img/work-thumbnails/kyotocooling.png';
import opexThumbnail from 'img/work-thumbnails/opex.png';
import texasInstrumentsThumbnail from 'img/work-thumbnails/texas-instruments.png';

import fuzion1 from 'img/work-images/fuzion-1.jpg';
import opex1 from 'img/work-images/opex-1.jpg';

export type Work = {
  dateStart?: Date;
  dateEnd: Date | null;
  yearOnly?: boolean;
  location?: string;
  workType?: string;
  position?: string;
  key: string;
  title: string;
  thumbnail?: string;
  content?: string;
  images?: WorkImage[];
};

export type WorkImage = {
  url: string;
  title?: string;
};

export const works: Work[] = [
  {
    dateStart: new Date('2019'),
    dateEnd: null,
    yearOnly: true,
    workType: 'Personal Project',
    position: 'Creator',
    key: 'fuzion',
    title: 'Fuzion',
    thumbnail: fuzionThumbnail,
    images: [
      { url: fuzion1 },
    ],
    content: `
**Fuzion** is a web application for connecting with a group of friends or associates. I started developing Fuzion to
deal with the inadequacies of Mumble for media-sharing. Me and a group of friends were already communicating via
Mumble, but we began wanting to share rich media and sync/watch YouTube videos with eachother. While Discord was
quickly becoming a cultural phenomenon, the freedom and joy of maintaining a private and personal communication
method for friends and family felt lost in the centralized nature of Discord.

The goal of Fuzion was to approach the convenience and rich-media focus of Discord, while maintaining the freedom
to self-host and maintain your own server.

During the quarantining of the recent pandemic, the development sped up significantly - and very quickly we migrated
completely from Mumble to our Fuzion instance to talk and share videos together. After a while, perhaps due to social
isolation, the friend group grew to an extended circle - with nightly events and a nearly constant virtual presence despite
the still relatively-small community size.

### Technologies
- Front-end
  - Typescript
  - Lit (lit-element)
  - Webpack
  - CSS/Sass
  - HLSjs
  - DashJS
- Back-end
  - Rust
  - C
  - Postgres
  - Redis
  - WebRTC
  - Janus Gateway
    - Custom C-plugin for Fuzion-specific requirements
  - Docker
  - actix-web
  - tokio
  - Serde
  - ffmpeg
  - S3
- Native Applications
  - Electron (Windows/Mac/Linux)
  - Kotlin (Android)
  - Iohook (Windows/Mac/Linux)
- Infrastructure
  - Gitlab (Windows/Mac/Linux)
  - Postfix/Dovecot/Webmail
  - Zabbix system monitoring
  - Nextcloud

### Skills
- Implemented voice chat, video chat, screensharing
  - Developed C-plugin for Fuzion-specific requirements
    - Packet parsing to build keyframe thumbnails
    - Extended with volume, force-muting, deafening
    - Configurable guest access by IP
- Implemented authorization/roles/authorities
  - Overwriteable permissions based on user, role, and role-weight
- Implemented database architecture
  - Implemented channel, chat, members, auth, etc
- Implemented CI pipeline
  - Automatic releases (stable/staging/debug) based on protected tags
  - Docker-based build pipelines
- Managing dedicated Linux box (Remote)
  - Managing Docker registry
  - Managing NPM registry
  - Managing multi-domain Postfix/Dovecot
  - Managing Gitlab instance
  - Multi-tenant Fuzion instance environments
- Implemented modular integration with multiple media sources
  - i.e., YouTube, Twitch, etc
- Implemented media uploading, processing, and transcoding/converting
  - Conversion and user feedback for conversion of media to MPEG-DASH and HLS
  - Handles the uploading of images and media, creating thumbnails for user previews
- Implemented modular upload processing
  - Currently supports S3 or local stores
- Implemented daily filesystem backups
  - Backup pruning on a monthly space
  - Has saved my butt several times

### Open-source Spinoffs & Contributions
- https://github.com/enzious/enum-str-derive
- https://github.com/enzious/actix-web-thiserror
- https://github.com/enzious/treebitmap
- https://github.com/redis-rs/redis-rs


...and much, much more
    `
  },
  {
    dateStart: new Date('2021-06-15'),
    dateEnd: null,
    location: 'Plano',
    workType: 'Full-time',
    position: 'Senior Member of Technical Staff',
    key: 'opex',
    title: 'OPEX',
    thumbnail: opexThumbnail,
    images: [
      { url: opex1 },
    ],
    content: `
**OPEX** is a company based in New Jersey that produces automation products for a variety of
commercial needs.
Starting with a focus on mail-handling machines, it's quickly and successfully made an entrance into
the warehouse automation industry, as well.
With many Fortune 100 customers, the company is on the forefront of providing solutions that make
warehouses more efficient and manageable for customer, employees, and integrators.
Currently, the company has solid solutions for automated store/retrieve, sorting, order management, and
sort-to-order workflows.

# Primary Projects

## Cortex WebUI

### React, Spring Boot, Postgres, Keycloak, Android

Cortex WebUI started as an application for commissioning the machines that OPEX manufactures. Cortex is a
system of software for supporting the machines - interfacing with WMS (Warehouse Management Software), and
providing data such as items handled and orders to process through the machine. WebUI started as software
with the purpose to set-up the machines within Cortex, which had been developed for years without any
front-facing interfaces.

My team had started as a generic software engineering team, however, after producing WebUI and seeing the
potential of the foundation me and my team had set down - WebUI began to take on more responsibilities as
an application. First, WebUI provided screens to understand the machines state and the allocation of its
resources. Then, with sort-to-order, WebUI was then developed to support running on handheld scanners -
which allowed customers to 'scan out' completed orders and ship them quickly. More and more features
continue to be added, and our team became more formally associated with services and web applications.

As time goes on, WebUI will continue to expand functionality, becoming a one-stop shop for interfacing with
the machines. With authentication and role-management - customers, operators, and service engineers can
all use and be familiar with WebUI and the state and operation of the machines.
`
  },
  {
    dateStart: new Date('2019'),
    dateEnd: null,
    yearOnly: true,
    workType: 'Personal Project',
    key: 'brett-dev',
    title: 'brett.dev',
    thumbnail: brettDevThumbnail,
    content: `
**brett.dev** is a simple portfolio site for documenting the work I've done in the industry. It's built with
ReactJS and MUI. For the most part, it's a dead-simple React application. Maybe one day it'll be a bit
more.

The UX (css, layout, etc) are all hand-made. I use MUI for it's neat little utilities but that's about it.
`
  },
  {
    dateStart: new Date('2020-07-15'),
    dateEnd: new Date('2021-05-15'),
    workType: 'Full-time',
    position: 'Senior Engineer',
    location: 'Remote',
    key: 'kyotocooling',
    title: 'KyotoCooling',
    thumbnail: kyotocoolingThumbnail,
    content: `
**KyotoCooling** was one of the coolest companies I've work for. KyotoCooling manufactures cooling solutions
for datacenters based around the 'Kyoto wheel', an energy-efficient way to transfer heat. The products it
produces are massive feats of engineering, and are very advanced for the industry in which they compete.

# Primary Projects

## KyotoLink

KyotoCooling's machines are filled to the brim with sensors. These sensors provide operators with low-level
details on the mechanical status of the many various components that comprise one unit. Using many embedded
systems, the sensors' data are collected and packaged until finally being sent into the cloud for processing.

With this data, KyotoCooling can monitor vitals, analyze many states and anomalies, and create 'events' that
must be acknowledged and addressed by service technicians. For many issues, KyotoCooling would know an issue
was imminent before it even became a problem for the end-user. This is the heart of the KyotoLink software
that I worked on at KyotoCooling.

The team I worked with was very small, but the product they produced was extremely well-made and solid. In this
team I worked in the cloud (AWS Lambda), database (Timescale/Postgres), back-end (Kotlin), and in the front-end (Vue). I worked
under some very sharp engineers and can say it was a very valuable experience in my professional career.

Most of the projects I worked on involved improving performance, user-experience, and working with customers
to improve the usability of the ticketing system and recording-keeping.
`
  },
  {
    dateStart: new Date('2019-07-15'),
    dateEnd: new Date('2020-05-15'),
    workType: 'Contractor',
    position: 'Senior Engineer L3',
    location: 'Plano, TX',
    key: 'intuit',
    title: 'Intuit',
    thumbnail: intuitThumbnail,
    content: `
**Intuit** was an interesting company for me, being one of the big Silicon Valley tech companies. My task at
Intuit was to move where needed on critical projects. Some of the many projects I worked on include refactoring
a major application - Intuit Tax Online - to React, assisting with compliance to CPPA (California Privacy Protection
Act), integrating partners with new API endpoints, and much more.

Intuit offered me a full-time position, but upon discussions of compensation - I politely declined.
`,
  },
  {
    dateStart: new Date('2013-05-15'),
    dateEnd: new Date('2019-07-15'),
    workType: 'Full-time',
    position: 'Jr. System Architect',
    location: 'Richardson, TX',
    key: 'texas-instruments',
    title: 'Texas Instruments',
    thumbnail: texasInstrumentsThumbnail,
    content: `
**Texas Instruments** is a company most people associate with calculators - but in truth, it's one of the leading
producers of semiconductors. Starting as an oil exploration company, Texas Instruments (then GSI, Geophysical
Service Inc.) has grown through many businesses and industries to become what it is today.

I started off with Texas Instruments as an intern, and after becoming a full-time employee found my place
developing greenfield projects to assist with internal and external business processes. Before leaving TI, I was
in the Solutions Architect pipeline, operating as a Junior Solutions Architect.

# Projects

## eRTP - Factory Offload Management Application

Designed and developed an application to manage all offloading of material to A/T sites.

## TIMS - Factory Tool Signal Intelligence Application

Designed and developed an application that uses signals from factory tools to take timely action when signals behave
abnormally.

## TSM - QA Test Program Management System

Designed and developed an application that applies a business workflow with approval processes over Git to enhance
the management of test programs.

### Awards

 - Technical Excellence Award (TEA) - 2017
`
  },
  // dateStart: new Date('2019-07'),
  // dateEnd: new Date('2020-05'),
];

