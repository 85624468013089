export type Post = {
  key: string,
  title: string,
  date: Date,
  author: string,
  content: string,
};

type Optionals = 'author' | 'date';

export const createDefaultPost = (
  partial: Partial<Pick<Post, Optionals>> &
    Omit<Post, Optionals>,
) => ({
  author: 'brett',
  date: new Date(),
  ...partial,
});

export const posts = [
  //   createDefaultPost({
  //     key: 'what-is-dev-sense',
  //     title: 'What is dev-sense?',
  //     content: [ ...Array(1) ]
  //       .map(() => `
  // This is test [content](https://google.com).
  //
  // | a | b |
  // | - | - |
  // | 1 | 2 |
  //       `)
  //       .join(' '),
  //   }),
];

