import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { PortfolioHeader } from 'js/components/portfolio-header';
import { Works } from 'js/components/works';
import { PageBody } from 'js/components/page-body';
import { posts } from 'js/content/posts';
import { Post } from 'js/components/post';

export const Landing = (): ReactElement => {
  return (
    <>
      <PortfolioHeader />

      <PageBody>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: 1,
          }}
        >
          <Works />

          {
            posts.length ? (
              <Box>
                <Typography
                  sx={{ color: '#F7D69F', fontWeight: 900, paddingLeft: 1 }}
                  variant="h4"
                >
                  Posts
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                  }}
                >
                  {posts.map((post) => <Post post={post} />)} 
                </Box>
              </Box>
            ) : null
          }
        </Box>
      </PageBody>
    </>
  );
};
