import React, { useState, ReactElement, useCallback } from 'react';
import Box from '@mui/material/Box';
import classNames from 'classnames';

import WorkWith from 'js/components/work-with';
import GetTheDetes from 'js/components/get-the-detes';

import { ActionBar } from './action-bar';

export const PortfolioHeader = (): ReactElement => {
  const [ collapsed, setCollapsed ] = useState(false);

  const handleCollapsedChange = useCallback((collapsed: boolean) => {
    setCollapsed(collapsed);
  }, []);

  return (
    <Box
      className={classNames({ collapsed })}
      sx={portfolioHeaderSx}
    >
      <Box className="foreground">
        <ActionBar onCollapsedChange={handleCollapsedChange} />

        <GetTheDetes />

        <Box className="introduction">
          <span className="hello">Hello</span>, my name is

          <Box className="name">Brett</Box>

          <WorkWith />
        </Box>
      </Box>
    </Box>
  );
};

const portfolioHeaderSx = {
  display: 'flex',
  backgroundImage: `linear-gradient(
    to bottom,
    #1D2132 0%,
    #24283b 100%
  )`,
  justifyContent: 'center',

  '& .foreground': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '1024px',
    height: '320px',
    background: 'rgba(255, 255, 255, .05)',
    overflow: 'hidden',

    '& .introduction': {
      fontSize: '20px',
      color: 'rgba(255, 255, 255, .25)',
      fontFamily: 'arial',
      paddingLeft: '32px',
      lineHeight: 1,

      '& .hello': {
        color: 'rgba(255, 255, 255, .5)',
      },

      '& .name': {
        color: '#FFFFFF',
        fontFamily: "'Didact Gothic', sans-serif",
        fontSize: '3.25rem',
      },
    },
  },
};
