import React, { ReactElement, ReactNode, useRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import { useSize } from 'js/utils/size';

type Size = 's' | 'm';

export const PageBody = (
  {
    backgroundSx,
    children,
    sx,
    withFooter = true,
  }: {
    backgroundSx?: BoxProps['sx'];
    children?: ReactNode;
    sx?: BoxProps['sx'];
    withFooter?: boolean;
  },
): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  const size = useSize<Size>(
    ref,
    'm',
    (domRect: DOMRect) => {
      const { width } = domRect;

      if (width > 400) {
        return 'm';
      }

      return 's';
    },
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          boxSizing: 'border-box',

          ...sx,
        }}
      >
        <Box
          ref={ref}
          className="background"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '1024px',
            maxWidth: '100vw',
            margin: '0 auto',
            height: '100%',
            background: 'rgba(255, 255, 255, .02)',

            ...backgroundSx,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,

              ...({
                s: { padding: 1, gap: 1 },
                m: { padding: 2, gap: 2 },
              }[size]),
            }}
          >
            {children}
          </Box>

          {
            withFooter ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  opacity: .4,
                  padding: 2,
                  background: 'rgba(0, 0, 0, .4)',
                }}
              >
                made with love ♥ 
              </Box>
            ) : null
          }
        </Box>
      </Box>
    </>
  );
};
