import React, { useState, useEffect, useRef, ReactElement } from 'react';
import ReactDOM, { createPortal } from 'react-dom';
import Box from '@mui/material/Box';
import ResizeObserver from 'react-resize-observer';

import Portals from 'js/components/portals';
import WhereAmI from 'js/components/where-am-i';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { SideMenu } from './sidemenu';

export const ActionBar = (
  {
    onCollapsedChange = () => void 0,
  }: {
    onCollapsedChange?: (collapsed: boolean) => void;
  }
): ReactElement => {
  const [ collapsed, setCollapsed ] = useState(false);
  const [ menuOpen, setMenuOpen ] = useState(false);

  const left = useRef(null);
  const right = useRef(null);
  const leftWidth = useRef<string | null>(null);
  const rightWidth = useRef<string | null>(null);

  const portal = useRef<HTMLElement | null>(null);
  if (portal.current === null) {
    portal.current = window.document.createElement('div');
    window.document.body.appendChild(portal.current);
  }

  const getSizes = () => {
    if (left?.current) {
      leftWidth.current = window.getComputedStyle(ReactDOM.findDOMNode(left.current) as Element).width;
    }

    if (right?.current) {
      rightWidth.current = window.getComputedStyle(ReactDOM.findDOMNode(right.current) as Element).width;
    }
  };

  const updateLayout = (barWidth: number) => {
    if (leftWidth?.current && rightWidth?.current) {
      const leftWidthPx = parseInt(leftWidth.current?.split('px')[0], 10);
      const rightWidthPx = parseInt(rightWidth.current?.split('px')[0], 10);

      if (leftWidthPx + rightWidthPx > barWidth) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    }
  }

  useEffect(() => {
    getSizes();

    return () => {
      portal.current?.remove();
      portal.current = null;
    };
  }, []);

  useEffect(() => {
    onCollapsedChange(collapsed);
  }, [ collapsed, onCollapsedChange ]);

  return (
    <Box sx={actionBarSx}>
      {
        (true || !collapsed)
          ? (
            <Box className="left">
              <Box className="content" ref={!collapsed ? left : undefined}>
                <Portals collapsed={collapsed} />
              </Box>
            </Box>
          ) : <></>
      }

      <Box className="right" ref={!collapsed ? right : undefined}>
        <WhereAmI collapsed={collapsed} />

        {
          collapsed
            ? (
              <Box className="hamburger" onClick={() => setMenuOpen(true)}>
                <FontAwesomeIcon icon={faBars} />
              </Box>
            )
            : <></>
        }
      </Box>

      <>
        {
          portal.current
            ? createPortal(
              <SideMenu
                onClose={() => setMenuOpen(false)}
                open={menuOpen}
              />,
              portal.current,
            )
            : <></>
        }
      </>

      <ResizeObserver
        onResize={(rect) => {
          getSizes();
          updateLayout(rect.width);
        }}
      />
    </Box>
  );
}

const actionBarSx = {
  display: 'flex',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  justifyContent: 'flex-end',

  '& .left': {
    display: 'flex',
    justifyContent: 'flex-start',
    flexShrink: 0,
    flexGrow: 1,
    alignItems: 'center',
    alignContent: 'flex-start',
  },

  '& .right': {
    display: 'flex',
    justifyContent: 'flex-end',
    flexShrink: 0,
    alignItems: 'center',

    '& .hamburger': {
      color: '#FFFFFF',
      fontSize: '2.3rem',
      padding: '.5rem .5rem .5rem 0rem',
      cursor: 'pointer',

      '&:hover': {
        color: '#F7D69F',
      },

      '& svg': {
        padding: '.5rem',
        width: '36px',
        height: '36px',
      },

      '& :hover svg': {
        background: 'rgba(255, 255, 255, .08)',
      },
    },
  },
};
