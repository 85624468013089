import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { WhereType, WHERES } from './where-am-i';

const REVERSE_WHERES = WHERES.reverse();

export const SideMenu = (
  {
    onClose,
    open,
  }: {
    onClose: () => void;
    open: boolean;
  },
): ReactElement => {
  return (
    <Box
      className={classNames({ open })}
      sx={sideMenuSx}
    >
      <div className="inner">
        <div className="header">
          <div className="left">
            <h1>Menu</h1>
          </div>

          <div className="right">
            <button className="close" onClick={() => onClose && onClose()}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>

        <div className="body">
          {
            REVERSE_WHERES.map((where) => {
              let { label, url } = where;

              return (
                <a
                  key={label}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {(({ type }) => {
                    let out = null;

                    switch (type) {
                      case WhereType.Github:
                        out = <FontAwesomeIcon icon={faGithub} />;
                        break;

                      case WhereType.LinkedIn:
                        out = <FontAwesomeIcon icon={faLinkedin} />;
                        break;

                      default:
                    }

                    if (out !== null) {
                      out = (
                        <span style={{ paddingRight: '.75rem', width: '2.2rem', display: 'inline-block' }}>
                          {out}
                        </span>
                      );
                    }

                    return out;
                  })(where)}

                  {where.label || '?'}
                </a>
              );
            })
          }
        </div>
      </div>
    </Box>
  );
}

const sideMenuSx = {
  position: 'absolute',
  top: 0,
  right: 0,
  width: 0,
  height: '100%',
  transition: 'width .3s',
  overflow: 'hidden',

  '&.open': {
    width: '95vw',

    '& .inner': {
    }
  },

  '& .inner': {
    width: '95vw',
    height: '100%',
    background: '#1D2132',
    color: '#FFFFFF',
    fontFamily: 'arial',

    '& .header': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '2px solid rgba(0, 0, 0, .2)',
    
      '&::after': {
        content: "''",
        clear: 'both',
        display: 'table',
      },

      '& .left': {
        flexGrow: 1,
        flexShrink: 0,
        textAlign: 'center',
      },

      '& .right': {
        flexGrow: 0,
        flexShrink: 0,
      },
    
      '& h1': {
        margin: '1rem',
        color: '#F7D69F',
        fontWeight: 'normal',
      },
      
      '& button': {
        margin: '1rem 1.2rem',
        fontSize: '2rem',
        border: 0,
        background: 'none',
        color: '#FFFFFF',
        cursor: 'pointer',

        '&:hover': {
          color: '#F7D69F',
        },
      },
    },

    '& .body': {
      '& a': {
        display: 'block',
        width: '100%',
        border: 0,
        padding: '1rem',
        color: '#FFFFFF',
        background: 'transparent',
        textAlign: 'left',
        fontSize: '1.5rem',
        textDecoration: 'none',

        '&:hover': {
          background: 'rgba(0, 0, 0, .1)',
          color: '#F7D69F',
        },
      },
    },
  },
};
