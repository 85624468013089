import React from 'react';
import { createRoot } from 'react-dom/client';

// import * as serviceWorker from 'service-worker';

import { BrettDev } from 'js/apps/brett-dev';

const root = createRoot(document.getElementById('root')!);

root.render(<BrettDev />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
