import React, { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";

import { defaultTheme, GlobalStyle } from "js/themes/default";

import { Landing } from "js/pages/landing";
import { NotFound } from "js/pages/not-found";
import { Work } from "js/pages/work";
import { Post } from "js/pages/post";
import { Works } from "js/pages/works";

export const BrettDev = (): ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />

        <CssBaseline />

        <Router />
      </ThemeProvider>
    </Box>
  );
};

const Router = (): ReactElement => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/posts/:key"
          element={<Post />}
        />

        <Route
          path="/works/:key"
          element={<Work />}
        />

        <Route
          path="/works"
          element={<Works />}
        />

        <Route
          path="/"
          element={<Landing />}
        />

        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
    </BrowserRouter>
  );
}
