import React, { useCallback, useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export type Size = 's' | 'm' | 'l';

export const useSize = <T,>(
  target: React.MutableRefObject<HTMLDivElement | null>,
  defaultValue: T,
  test: (domRect: DOMRect) => T,
): T => {
  const [size, setSize] = useState<T>(defaultValue);

  const handleDOMRect = useCallback((domRect: DOMRect) => {
    setSize(test(domRect));
  }, [test]);

  useLayoutEffect(() => {
    const { width } = target.current?.getBoundingClientRect() || {};

    if (width && target.current) {
      handleDOMRect(target.current.getBoundingClientRect());
    }
  }, [handleDOMRect, target]);

  useResizeObserver(target, (entry) => handleDOMRect(entry.contentRect))

  return size;
};
