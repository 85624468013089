import React from 'react';
import { faArrowDown, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import filesize from 'filesize';
import Box from '@mui/material/Box';

import data from 'json/data.json';

const GetTheDetes = () => {
  return (
    <Box sx={sx}>
      <a
        href="/girton_brett_resume.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="label">
          Download Résumé<br />

          <span className="details">PDF | {filesize(data.resumeFileSize)}</span>
        </span>

        <span className="icon">
          <FontAwesomeIcon icon={faArrowDown} />
        </span>
      </a>

      <a
        href={['m', 'ai', 'lt', 'o', '', ':', 'm', 'e', '@', 'br', 'ett', '.', 'dev'].join('')}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="label">
          Let's chat!
        </span>

        <span className="icon">
          <FontAwesomeIcon icon={faEnvelope} />
        </span>
      </a>
    </Box>
  );
};

const sx = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  bottom: 0,
  right: 0,
  padding: '.75rem',
  width: '280px',
  maxWidth: '100%',

  '& > a': {
    display: 'flex',
    flowDirection: 'row',
    borderRadius: '5px',
    border: '2px solid rgba(255, 255, 255, .2)',
    padding: '.5rem .5rem .5rem .5rem',
    margin: '.25rem',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'arial',
    color: '#FFFFFF',
    cursor: 'pointer',
    textDecoration: 'none',

    '&:first-of-type': {
      borderColor: '#F7D69F',
      // background: #0099FF,
      color: '#F7D69F',
    },

    '&:first-of-type:hover': {
      background: '#F7D69F',
      borderColor: '#F7D69F',
      color: '#222639',
    },

    '@media (max-width: 768px)': {
      '&:last-child': {
        display: 'none',
      },
    },

    '&:hover': {
      background: 'rgba(255, 255, 255, .1)',
      color: '#FFFFFF',
    },

    '& > .icon': {
      display: 'flex',
      flexShrink: 1,
      flexGrow: 0,
      alignItems: 'center',
      justifyContent: 'center',
      width: '1.5rem',
    },

    '& > .label': {
      flexGrow: 1,
      fontWeight: 700,
      paddingLeft: '.125rem',

      '& .details': {
        fontSize: '11px',
      },
    },
  },
};

export default GetTheDetes;
