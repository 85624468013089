import React, { Component } from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';

type WorkWithProps = {};

type WorkWithState = {
  currentWord: number,
};

const WorkWith = class extends Component<WorkWithProps, WorkWithState> {
  words: string[] = [];
  currentWordIdx?: number;
  timeout?: number;
  wordRefs: Map<number, Word | null> = new Map<number, Word | null>();

  static defaultProps = {};

  constructor(props: WorkWithProps) {
    super(props);

    this.words = [
      'confidence', 'services', 'UX', 'databases', 'CI', 'golang', 'rustlang', 'java', 'react', 'spring', 'electron',
      'pub/sub', 'linux', 'typescript', 'sql', 'shell scripts', 'cloud', 'infrastructure', 'kotlin', 'vuejs', 'aws',
      'dms', 'actix',
    ];
  }

  componentDidMount() {
    this.nextWord(3000);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  firstWords() {

  }

  nextWord(timeShown = 1500) {
    let currentWord = this.currentWordIdx !== undefined ? this.words[this.currentWordIdx] : undefined;
    let nextWordIdx = (this.currentWordIdx === undefined || this.currentWordIdx === this.words.length - 1)
      ? 0
      : ((this.currentWordIdx || 0) + 1);
    let nextWord = this.words[nextWordIdx];

    if (this.currentWordIdx !== undefined && currentWord !== undefined) {
      for (let i = 0; i <= currentWord.length; i++) {
        this.animateLetterOut(this.currentWordIdx, i);
      }
    }

    this.wordRefs.get(nextWordIdx)?.setShow(true);
    for (let i = 0; i <= nextWord.length; i++) {
      this.animateLetterIn(nextWordIdx, i);
    }

    this.currentWordIdx = nextWordIdx;
    this.timeout = setTimeout(() => this.nextWord(), timeShown);
  }

  animateLetterOut(wordIdx: number, progression: number) {
    setTimeout(() => {
      this.wordRefs.get(wordIdx)?.setProgression(false, progression);
    }, progression * 60);
  }

  animateLetterIn(wordIdx: number, progression: number) {
    setTimeout(() => {
      this.wordRefs.get(wordIdx)?.setProgression(true, progression);
    }, 340 + (progression * 60));
  }

  render() {
    return (
      <Box
        sx={workWithSx}
      >
        <span>I work with&nbsp;</span>

        <span>
          {
            this.words.map((word, idx) => 
              <Word
                key={word}
                idx={idx}
                ref={ref => this.wordRefs.set(idx, ref)}
                word={word}
              />
            )
          }
        </span>
      </Box>
    );
  }
}

const workWithSx = {
  fontFamily: "'Open Sans', sans-serif",
  fontSize: '30px',
  height: '40px',
  lineHeight: 1,

  // '@media (max-width: 768px)': {
  //   fontSize: '30px',
  //   marginTop: '1rem',
  // },

  '& > span': {
    '&:last-child': {
      position: 'relative',
    },

    display: 'inline-block',
    verticalAlign: 'top',
    margin: 0,
  },

  '& .word': {
    position: 'absolute',
    width: '400px',
    opacity: 0,

    '&.show': {
      opacity: 1,
    },
  },

  '& .letter': {
    display: 'inline-block',
    position: 'relative',
    float: 'left',
    transform: 'translateZ(25px)',
    transformOrigin: '50% 50% 25px',
  },

  '& .letter.out': {
    transform: 'rotateX(90deg)',
    transition: 'transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)',
  },

  '& .letter.behind': {
    transform: 'rotateX(-90deg)',
  },

  '& .letter.in': {
    transform: 'rotateX(0deg)',
    transition: 'transform 0.28s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  },

  '& .wisteria': {
    color: '#8e44ad',
  },

  '& .belize': {
    color: '#2980b9',
  },

  '& .pomegranate': {
    color: '#c0392b',
  },

  '& .green': {
    color: '#16a085',
  },

  '& .midnight': {
    color: '#7fd5ea',
  },
};

const COLORS: string[] = [ 'wisteria', 'belize', 'pomegranate', 'green', 'midnight' ];

type WordProps = {
  className?: string,
  idx: number,
  word: string,
}

type WordState = {
  progression: number,
  show: boolean,
  movingIn: boolean,
}

class Word extends Component<WordProps, WordState> {
  constructor(props: WordProps) {
    super(props);

    this.state = {
      movingIn: true,
      progression: 0,
      show: false,
    };
  }

  setShow(show: boolean) {
    this.setState({ show });
  }

  setProgression(movingIn: boolean, progression: number) {
    this.setState({
      movingIn,
      progression,
    });
  }
  
  render() {
    let { className, idx, word } = this.props;
    let { movingIn, progression, show } = this.state;

    return (
      <span
        className={classNames({
          [ COLORS[idx % COLORS.length] ]: true,
          className,
          'word': true,
          show,
        })}
      >
        {
          [...word].map((letter, letterIdx) => {
            return (
              <span
                key={`${letterIdx}-${letter}`}
                className={classNames({
                  'behind': movingIn,
                  'letter': true,
                  'in': movingIn && progression > letterIdx,
                  'out': !movingIn && progression > letterIdx,
                })}
              >
                {letter}
              </span>
            );
          })
        }
      </span>
    );
  }
};

export default WorkWith;
