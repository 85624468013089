import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';

import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export enum WhereType {
  Github,
  LinkedIn,
  Other,
}

class Where {
  type?: WhereType;
  label?: string;
  url?: string;
}

export const WHERES: Where[] = [
  {
    label: 'Github',
    type: WhereType.Github,
    url: 'https://github.com/enzious',
  },
  {
    label: 'LinkedIn',
    type: WhereType.LinkedIn,
    url: 'https://linkedin.com/in/brett-girton',
  },
];

const WhereAmI = (
  {
    collapsed,
  }: {
    collapsed: boolean;
  },
): ReactElement => {
  return (
    <Box
      sx={whereAmISx}
    >
      {
        !collapsed ? WHERES.map((where) => {
          let { label, url } = where;

          return (
            <a key={label} href={url} target="_blank" rel="noopener noreferrer">
              {(({ type }) => {
                let out = null;
                switch (type) {
                  case WhereType.Github:
                    out = <FontAwesomeIcon icon={faGithub} />;
                    break;

                  case WhereType.LinkedIn:
                    out = <FontAwesomeIcon icon={faLinkedin} />;
                    break;

                  default:
                }

                if (out !== null) {
                  out = (
                    <span style={{ paddingRight: '.5rem' }}>
                      {out}
                    </span>
                  );
                }

                return out;
              })(where)}

              {where.label || '?'}
            </a>
          );
        }) : null
      }

      <a
        className="lets-chat"
        href={['m', 'ai', 'lt', 'o', '', ':', 'm', 'e', '@', 'br', 'ett', '.', 'dev'].join('')}
        target="_blank"
        rel="noopener noreferrer"
      >
        Let's Chat
      </a>
    </Box>
  );
};

const whereAmISx = {
  display: 'flex',
  flexDirection: 'row',
  padding: '.5rem',

  '& > a': {
    margin: '.0725rem .125rem',
    padding: '.5rem .75rem',
    fontSize: '1rem',
    color: '#FFFFFF',
    fontFamily: 'arial',
    cursor: 'pointer',
    textDecoration: 'none',
    borderRadius: '3px',

    '&:last-child': {
      marginRight: 0,
    },

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .075)',
    },

    '&.lets-chat': {
      border: '1px solid #F7D69F',

      '&:hover': {
        background: '#F7D69F',
        color: '#222639',
      },
    },
  },
};

export default WhereAmI;
