import React, { ReactElement } from 'react';
import { format } from 'date-fns';

export const WorkDate = (
  {
    dateStart,
    dateEnd,
    yearOnly = false,
  }: {
    dateStart?: Date;
    dateEnd?: Date | null;
    yearOnly?: boolean;
  },
): ReactElement => {
  const dateFormat = yearOnly ? 'Y' : 'LLL Y';

  return dateEnd
    ? (
      (dateStart && format(dateStart, dateFormat) !== format(dateEnd, dateFormat))
        ? (
          <>
            {format(dateStart, dateFormat)} - {format(dateEnd, dateFormat)}
          </>
        )
        : (
          <>
            {format(dateEnd, dateFormat)}
          </>
        )
    )
    : (
      (dateStart && dateEnd === null)
        ? <>{format(dateStart, dateFormat)} -</>
        : <></>
    );
};
