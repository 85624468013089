import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

import { Work } from 'js/content/works';
import { Size } from 'js/utils/size';

import { WorkDate } from './work-date';

export const WorkItem = (
  {
    size,
    work,
  }: {
    size: Size;
    work: Work;
  }
): ReactElement => {
  const { key, title, dateStart, dateEnd, yearOnly, thumbnail } = work;

  if (size === 's') {
    return (
      <Link
        key={key}
        color="text.primary"
        component={RouterLink}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          background: 'rgba(0, 0, 0, .2)',
          borderRadius: '3px',
          padding: 1,
          textDecoration: 'none',

          '&:hover': {
            background: 'rgba(0, 0, 0, .23)',
          },

          '&:hover .title': {
            color: '#F7D69F',
            cursor: 'pointer',
          },
        }}
        to={`/works/${key}`}
      >
        <Box className="title" sx={{ flexGrow: 1 }}>
          {title}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexShrink: 0,
            opacity: .4,
            fontSize: '.8125rem',
            alignItems: 'center',
          }}
        >
          <WorkDate dateStart={dateStart} dateEnd={dateEnd} yearOnly={yearOnly} />
        </Box>
      </Link>
    )
  }

  return (
    <Link
      key={key}
      color="#FFFFFF"
      component={RouterLink}
      sx={{
        display: 'block',
        position: 'relative',
        aspectRatio: '16/9',
        boxShadow: '20px 20px 90px rgba(0, 0, 0, .4) inset',
        borderRadius: '3px',
        // cursor: 'pointer',
        overflow: 'hidden',

        '& img': {
          position: 'absolute',
          top: '0%',
          left: '0%',
          width: '100%',
          height: '100%',
          transition: 'left 1s, top 1s, width 1s, height 1s',
        },

        '&:hover img': {
          top: '-6.25%',
          left: '-6.25%',
          width: '112.5%',
          height: '112.5%',
        },

        '& .shadow': {
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          boxShadow: '0px 0px 14px rgba(0, 0, 0, .25) inset, 0 0 3px rgba(0, 0, 0, .8)',
          border: '2px solid rgba(255, 255, 255, .05)',
          transition: 'border-width .125s',

          '& .title': {
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            textAlign: 'right',
            paddingX: '.4125rem',
            paddingY: '.125rem',
            background: 'rgba(0, 0, 0, .125)',
            fontSize: '.75rem',
            transition: 'linear .075s font-size, linear .1s color',
            backdropFilter: 'blur(10px)',
          },
        },

        '&:hover .shadow': {
          borderWidth: '5px',

          '& .title': {
            fontSize: '1rem',
            color: '#F7D69F',
          },
        },
      }}
      to={`/works/${key}`}
    >
      {
        thumbnail ? (
          <img src={thumbnail ?? undefined} alt={title} />
        ) : null
      }

      <Box className="shadow">
        <Box className="title">
          {title}
        </Box>
      </Box>
    </Link>
  );
};
