import createTheme from '@mui/material/styles/createTheme';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

html, body
{
  padding: 0;
  margin: 0;
  background-color: #1D2132;
}

* { box-sizing: border-box; }

`;

export const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#1e243c',
    },
    primary: {
      main: 'rgb(247, 214, 159)'
    },
  },
});
