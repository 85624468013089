import React, { ReactElement, useCallback } from 'react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { PageBody } from 'js/components/page-body';
import { SmallHeader } from 'js/components/small-header';
import { WorkImage, works } from 'js/content/works';
import { SmallSubHeader } from 'js/components/small-sub-header';
import { WorkDate } from 'js/components/work-date';

import { NotFound } from './not-found';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const QuiltedImageList = (
  {
    images,
  }: {
    images: WorkImage[];
  },
) => {
  const handleClick = useCallback((evt: React.MouseEvent<HTMLImageElement> & { target: HTMLImageElement }) => {
    window.open(evt.target.src, '_blank');
  }, []);

  return (
    <ImageList
      sx={{ width: 500, height: 550 }}
      variant="quilted"
      cols={2}
      rowHeight={250}
    >
      {
        images.map((item) => (
          <ImageListItem
            key={item.url}
            cols={1}
            rows={1}
            sx={{
              '& img': {
                border: '1px solid #DDDDDD44',
                borderRadius: 2,
                cursor: 'pointer',

                '&:hover': {
                  borderColor: '#CEE61B',
                },
              },
            }}
          >
            <img
              src={item.url}
              alt={item.title}
              loading="lazy"
              onClick={handleClick}
            />
          </ImageListItem>
        ))
      }
    </ImageList>
  );
}


export const Work = (): ReactElement => {
  const { key } = useParams<{ key: string }>();

  const work = key && works.find(({ key: iKey }) => iKey === key);

  if (!work) {
    return <NotFound />;
  }

  const { title, images, dateStart, dateEnd, yearOnly, location, workType, position, content } = work;

  return (
    <>
      <SmallHeader
        subHeader={
          <SmallSubHeader>
            <Breadcrumbs>
              <Link
                component={RouterLink}
                to="/works/"
              >
                Works
              </Link>

              <Typography color="inherit">
                {title}
              </Typography>
            </Breadcrumbs>
          </SmallSubHeader>
        }
      />

      <PageBody>
        <Box>
          <Typography variant="h5">
            {title}
          </Typography>

          <Breadcrumbs
            sx={{
              '& li, & li *': {
                fontSize: 14,
              },
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: 'rgba(255, 255, 255, .5)' }}
            >
              <WorkDate dateStart={dateStart} dateEnd={dateEnd} yearOnly={yearOnly} />
            </Typography>

            {
              location ? (
                <Typography color="inherit">
                  {location}
                </Typography>
              ) : null
            }

            {
              workType ? (
                <Typography color="inherit">
                  {workType}
                </Typography>
              ) : null
            }

            {
              position ? (
                <Typography color="inherit">
                  {position}
                </Typography>
              ) : null
            }
          </Breadcrumbs>

          <Box sx={{ color: '#C8C8C8' }}>
            <Box
              sx={{
                '& p, h1, h2, h3, h4, h5': {
                  margin: '10px 0',
                },
              }}
            >
              <ReactMarkdown
                linkTarget="_blank"
                remarkPlugins={[remarkGfm]}
                components={{
                  a: ({ href, children }) => {
                    return (
                      <Link href={href} target="_blank">{children}</Link>
                    );
                  },
                }}
              >
                {content ?? 'Nothing here, yet.'}
              </ReactMarkdown>
            </Box>

            {
              images?.length ? (
                <QuiltedImageList images={images} />
              ) : null
            }
          </Box>
        </Box>
      </PageBody>
    </>
  );
}
