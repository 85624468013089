import Link from '@mui/material/Link';
import React, { ReactElement } from 'react';

export const LetsChat = (): ReactElement => {
  return (
    <Link
      className="lets-chat"
      href={['m', 'ai', 'lt', 'o', '', ':', 'm', 'e', '@', 'br', 'ett', '.', 'dev'].join('')}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        border: '1px solid #F7D69F',
        padding: 1,
        borderRadius: '5px',
        textDecoration: 'none',
        color: '#FFFFFF',

        '&:hover': {
          background: '#F7D69F',
          color: '#222639',
        },
      }}
    >
      Let's Chat
    </Link>
  );
};
