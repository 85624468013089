import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from '@mui/material/Typography';

import { PageBody } from 'js/components/page-body';
import { SmallHeader } from 'js/components/small-header';

export const NotFound = (): ReactElement => {
  return (
    <>
      <SmallHeader />

      <PageBody
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,

          '&  .background': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: 2,
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
          }}
        >
          <HelpOutlineIcon sx={{ fontSize: '128px' }} />

          <Typography variant="h6" sx={{ fontWeight: '900' }}>
            404 Not Found
          </Typography>

          <Typography sx={{ opacity: .6 }}>
            Where the <i>heck</i> you is??
          </Typography>
        </Box>
      </PageBody>
    </>
  );
};

