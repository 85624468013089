import React, { ReactElement } from 'react';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';

const Portals = (
  {
    collapsed,
  }: {
    collapsed: boolean;
  }
): ReactElement => {
  return (
    <Box sx={sx}>
      <a href="https://fzn.im/w/general" target="_blank" rel="noopener noreferrer">
        {'My Fuzion' || '?'}

        {!collapsed ? <FontAwesomeIcon icon={faComment} /> : null}
      </a>
    </Box>
  );
};

export default Portals;

const sx = {
  display: 'flex',
  flexDirection: 'row',
  padding: '.5rem',

  '& > a': {
    margin: 'calc(.0725rem + .125rem) calc(.125rem + .125rem)',
    padding: 'calc(.5rem - .125rem) calc(.75rem - .125rem)',
    fontSize: '.9rem',
    color: '#FFFFFF',
    fontFamily: 'arial',
    cursor: 'pointer',
    textDecoration: 'none',
    borderRadius: '3px',
    border: '1px solid rgba(255, 255, 255, .3)',

    '&:last-child': {
      marginRight: 0,
    },

    '& .svg-inline--fa': {
      margin: '0 .1rem 0 .3rem',
    },

    '&:hover': {
      background: '#CEE61B',
      border: '1px solid #CEE61B',
      color: '#222639',
    },

    '&.lets-chat': {
      border: '1px solid #F7D69f',

      '&:hover': {
        background: '#F7D69F',
        color: '#222639',
      },
    },
  },
};
